.AboutUsPageCe1d497009c14c57B42bF34b5b96bf13 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("https://mp1md-pub.s3-us-west-2.amazonaws.com/content/jblRhieXB7WQvOjD_regular.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.AboutUsPageCe1d497009c14c57B42bF34b5b96bf13 main {
    background: transparent;
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
}

.AboutUsPageCe1d497009c14c57B42bF34b5b96bf13 .content {
    height: auto;
    padding-bottom: 120px;
}
.Body448e426a3c514a419f182ad4f098a50c {
  display: flex;
  flex-direction: column;
}

/* .MarketingCard */

.Body448e426a3c514a419f182ad4f098a50c .MarketingCard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #08390780;
  text-align: center;
  padding: 0;
  color: #fff;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .Body448e426a3c514a419f182ad4f098a50c .MarketingCard {
    border-radius: 0.625rem;
  }
}

@media only screen and (min-width: 1024px) {
  .Body448e426a3c514a419f182ad4f098a50c .MarketingCard {
    flex-direction: row;
  }
  .Body448e426a3c514a419f182ad4f098a50c .MarketingCard.odd {
    flex-direction: row-reverse;
  }
}

/* .ImageColumn */

.Body448e426a3c514a419f182ad4f098a50c .ImageColumn {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  max-width: 100%;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .Body448e426a3c514a419f182ad4f098a50c .ImageColumn {
    flex: 0.5;
  }
}

/* .ImageWrapper */

.Body448e426a3c514a419f182ad4f098a50c .ImageColumn .ImageWrapper {
  flex-grow: 1;
}

/* .CardImage */

.Body448e426a3c514a419f182ad4f098a50c .CardImage {
  width: 100%;
  height: 100%;
  min-height: 37.5rem;
  max-height: 16.875rem;
  object-fit: cover;
  background-repeat: no-repeat;
}

.Body448e426a3c514a419f182ad4f098a50c .slug-about-posts-mission .CardImage {
  background-position: 0% 3%;
  background-size: 120%;
}

.Body448e426a3c514a419f182ad4f098a50c .slug-about-posts-vision .CardImage  {
  background-position: 53% 3%;
  background-size: cover;
}

@media only screen and (min-width: 1024px) {
  .Body448e426a3c514a419f182ad4f098a50c .CardImage {
    min-height: auto;
    max-height: none;
  }
  .Body448e426a3c514a419f182ad4f098a50c .slug-about-posts-vision .CardImage  {
    background-size: 112%;
  }
}

/* .CardHeading */

.Body448e426a3c514a419f182ad4f098a50c .MarketingCards .MarketingCard .CardHeading {
  margin-bottom: 1.25rem;
  padding-top: 2rem;
  font-family: Oseberg;
  font-size: 2.375rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-transform: uppercase;
}

/* .CardText */

.Body448e426a3c514a419f182ad4f098a50c .CardText {
  font-size: 1rem;
  color: #fff;
  font-weight: 200;
  font-family: Poppins;
  padding: 0.625rem 0.625rem 0 0.625rem;
  line-height: 1.8rem;
}

/* .CardTextContext */

.Body448e426a3c514a419f182ad4f098a50c .CardTextContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 43rem;
  margin: 0 auto;
  padding: 2rem;
}

@media only screen and (min-width: 1024px) {
  .Body448e426a3c514a419f182ad4f098a50c .CardTextContent {
    flex: 0.5;
  }
}
.Header8e8328dbDa064b259ce1Eed55fdb1402 {
  display: flex;
  flex-direction: column;
}

.Header8e8328dbDa064b259ce1Eed55fdb1402 .background-image {
  opacity: 0.5;
}

.Header8e8328dbDa064b259ce1Eed55fdb1402 .header-top-wrapper {
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 1;
}

.Header8e8328dbDa064b259ce1Eed55fdb1402 .header-top-opacity {
  opacity:  0.85;
  background-color: black;
  position: absolute;
  inset: 0;
}

.Header8e8328dbDa064b259ce1Eed55fdb1402 .background-image {
  background-image: url(https://images.unsplash.com/photo-1712891444420-41fc1bce23fd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.MarketingBody4c2805f8435e4532B853E20d52f5d567 {
  padding: 2em;
   background-color: rgba(2, 70, 38, 0.6);
}
.gf-area-marketing .SecondaryPage9bfa4730628f48ecBf797fe6896ad240 main {
  padding: 0 1rem;
  padding-top: 8rem;
}
.JoinUsPage205d85ef01c94aa0A932F91f59964e61 main {
  margin-top: 4rem;
}
.SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 {
  margin-top: 140px;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, 0.222);
  text-align: center;
  color: white;
  font-family: "Oseberg";
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 {
    font-size: 340%;
    margin-top: 135px;
    margin-bottom: 0px;
  }
}

.SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 .button > a {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    color: white;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 110%;
    text-transform: uppercase;
    display: inline-block;
    padding: 0.5rem 2rem;
    background-color: #ed2e38;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.SecondaryMarketingHeroFa77cb7a13964ef2B12a59c71552b6b1 .button > a:hover {
    --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.PageQuote37fedb1949e1417bA4b700c352d1290d {
  display: flex;
  flex-wrap: wrap;
}
.HomeHeader5f8f3dceBef1439680b71ffd8361f47f {
  display: flex;
  flex-direction: column;
}

.HomeHeader5f8f3dceBef1439680b71ffd8361f47f .background-image {
  opacity: 0.5;
}

.HomeHeader5f8f3dceBef1439680b71ffd8361f47f .header-top-wrapper {
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 1;
}

.HomeHeader5f8f3dceBef1439680b71ffd8361f47f .header-top-opacity {
  opacity:  0.85;
  background-color: black;
  position: absolute;
  inset: 0;
}

.HomeHeader5f8f3dceBef1439680b71ffd8361f47f .background-image {
  background-image: url(https://images.unsplash.com/photo-1712891444420-41fc1bce23fd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.Social2b50fa251a6c4565A80cE125cad6c157 a:hover {
  color: #3bdd80;
}
.AboutDescription6b553888B2fe4afe92cf895863283189 {
  padding-bottom: 3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 200;
  font-family: Poppins;
  line-height: 2em;
}

@media (max-width: 768px) {
  .AboutDescription6b553888B2fe4afe92cf895863283189 {
    padding-bottom: 4rem;
    font-size: 16px;
    line-height: 1.8em;
    text-align: center;
    line-height: 2.4em;
  }
}
.ContactA0b99c2dE090451cBb26Dfedbf8a0725 {
  display: flex;
  column-gap: 3rem;
  row-gap: 1rem;
  flex-direction: column;
}

.ContactA0b99c2dE090451cBb26Dfedbf8a0725 .info-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.ContactA0b99c2dE090451cBb26Dfedbf8a0725 .info-item:hover {
  color: #3bdd80;
}

.ContactA0b99c2dE090451cBb26Dfedbf8a0725 .info-item svg {
  height: 1rem;
  width: 1rem;
}

@media only screen and (min-width: 768px) {
  .ContactA0b99c2dE090451cBb26Dfedbf8a0725 {
    flex-direction: row;
  }
}
.SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 {
  display: flex;
  flex-direction: column;
}

.SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 .background-image {
  opacity: 0.5;
}

.SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 .header-top-wrapper {
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 1;
}

.SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 .header-top-opacity {
  opacity:  0.85;
  background-color: black;
  position: absolute;
  inset: 0;
}

.SecondaryMarketingHeader655d308413bf4575B91e856886ef8815 .background-image {
  background-image: url(https://images.unsplash.com/photo-1712891444420-41fc1bce23fd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.DefaultFooterA4b6036c59024002A62eD4a7111475ff {
  background-color: #000;    
  color: #ddd;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff > * {
  text-align: center;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .LogoWrapper {
  font-size: 90%;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .LogoWrapper:hover {
  --tw-text-opacity: 1;
  color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .GroupCopyright {
  font-size: 90%;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .GroupFlowTag .PoweredBy {
  font-size: 90%;
}

.DefaultFooterA4b6036c59024002A62eD4a7111475ff .site-name-logo {
  height: 45px;
  width: 240px;
}
.MessagePagesE6b9093aCae949c0972cB9a0bc31d50d {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.SecondaryNav81aa7e436b924d59A6a9A89f2ad8c626 {
  padding: 1rem 0;
}

.SecondaryNavbar {
  background: #e0e7e1;
  border-bottom: 1px solid #ccc;
}

.SecondaryNavbar a {
  color: #333;
}

.SecondaryNavbar .text-spp-red {
  color: #1C8C2B;
}

.SecondaryNavbar .text-spp-red:hover {
  color: #1C8C2B;
}

.SecondaryNavbar a:hover {
  color: #1C8C2B;
}

.ContentWithSidebar {
  background: #f3f3f3;
}
.AboutLevelingPage2717fcd276864d0bBfa25737127e53a0 .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.HeroE60e05fd3861462eB2cf02778b9adda2 {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 {
    margin-top: 10rem;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .homepage-forest-backdrop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  text-align: center;
  max-width: 75rem;
  width: 100%;
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "heading image"
      "buttons image";
    gap: 0;
    max-width: 75rem;
    margin-top: 0;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .external-content p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .hero-header {
  grid-area: heading;
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: #fff;
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0em;
  text-transform: uppercase;
  font-family: "Oseberg", sans-serif;
  font-weight: normal;
  margin: 0;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .hero-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: end;
  }
  .HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .hero-header > * {
    max-width: 25rem;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons {
    gap: 1rem;
    margin-top: 1rem;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons .Button {
  width: 19.75rem;
  height: 4.25rem;
  padding: 1rem 2rem;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.125rem;
  border-radius: 3.125rem;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons .Button.hero-button-1 {
  background: var(--button-1);
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons .Button.hero-button-1:hover {
  background: var(--button-1-hover);
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons .Button.hero-button-2 {
  background: var(--button-2);
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .buttons .Button.hero-button-2:hover {
  background: var(--button-2-hover);
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .hero-image {
  grid-area: image;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .hero-content .hero-image {
    width: 100%;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .home-hero-description {
  max-width: 36rem;
  padding-top: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .home-hero-description {
    padding-top: 6rem;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .home-hero-description h1 {
  font-size: 1.8rem;
  letter-spacing: 0em;
  text-transform: uppercase;
  font-family: Oseberg, sans-serif;
  font-weight: 400;
  margin: 0 0 2rem;
}

@media (min-width: 768px) {
  .HeroE60e05fd3861462eB2cf02778b9adda2 .home-hero-description h1 {
    font-size: 2rem;
  }
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .home-hero-description p {
  font-weight: 400;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .homepage-main-content {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-banner-description-container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .hero-banner-description-Innercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .description-para-container {
  margin-top: 1rem;
  max-width: 36rem;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.HeroE60e05fd3861462eB2cf02778b9adda2 .description-para-container p {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 5rem;
  width: 100%;
  padding: 0 1.5rem;
  background-color: rgba(0, 0, 0, 0.85);
}

.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: -1;
}

.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .linked-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .logo {
  width: 17rem;
}

.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .NavigationMenuButton {
  color: #fff;
  opacity: 0.5;
  display: flex;
}

.ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */
@media (min-width: 900px) {
  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 {
  }

  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .linked-logo {
    flex-grow: 1;
  }

  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .logo {
    /* width: 12rem; */
  }

  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .navigation {
    flex-grow: 1;
  }

  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .Navigation {
    display: flex;
  }

  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .Navigation {
  }
  .ShortHeader5eb0127c2b6b4eb1A027C2a06226fc04 .logo {
    width: 22rem;
  }
}
.public-events{
margin-top: 10rem;
}
.AboutHero10bfbed61141e4e1b954c9097bbd652b1 {
  margin-top: 140px;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, 0.222);
  text-align: center;
  color: white;
  font-family: "Oseberg";
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .about-description { 
  margin: 0 auto;
  max-width: 900px;
  padding-bottom: 3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 200;
  font-family: Poppins;
  line-height: 2em;
}

@media (max-width: 768px) {
  .AboutHero10bfbed61141e4e1b954c9097bbd652b1 .about-description {
    padding-bottom: 4rem;
    font-size: 16px;
    line-height: 1.8em;
    text-align: center;
    line-height: 2.4em;
  }
}

@media (min-width: 640px) {
  .AboutHero10bfbed61141e4e1b954c9097bbd652b1 {
    font-size: 340%;
    margin-top: 135px;
    margin-bottom: 0px;
  }
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .Button.cta-button {
  width: 19.75rem;
  height: 4.25rem;
  padding: 1rem 2rem;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 3.125rem;
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .Button.cta-button.cta-button-reserve {
  background-color: var(--button-1);
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .Button.cta-button.cta-button-reserve:hover {
  background-color: var(--button-1-hover);
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .Button.cta-button.cta-button-host {
  background-color: var(--button-2);
}

.AboutHero10bfbed61141e4e1b954c9097bbd652b1 .Button.cta-button.cta-button-host:hover {
  background-color: var(--button-2-hover);
}
.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f {
  height: 46rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .hero-content {
  padding: 0 2rem;
  text-align: center;
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f h1,
.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .external-content h1 {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0em;
  text-transform: uppercase;
  font-family: "Oseberg", sans-serif;
  font-weight: normal;
  margin-top: 2rem;
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .hero-button-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .hero-button {
  margin-top: 3rem;
  height: 3rem;
  width: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .button-svg {
  height: 3rem; 
  width: 3rem; 
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .hero-button:hover svg.button-svg {
  transform: scale(0.6);
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .hero-button:hover {
  background-color: rgba(255, 255, 255, 0.312);
  border-radius: 1.5rem;
}

@media (min-width: 768px) {
  .HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f {
    height: 48rem;
  }

  .HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f h1 {
    font-size: 3rem;
  }
}

.HomePageHeroA3b8ae34D78f448c8a80F5110c31ef3f .button-svg {
  transition: transform 0.3s ease; 
}
.DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 1.5rem;
  position: relative;
}

.DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .linked-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .logo {
  width: 17rem;
}

.SideCar {
  padding: 2rem;
  background-image: linear-gradient(rgba(0, 36, 19, 0.91), rgba(0, 36, 19, 0.91)), url('https://mp1md-pub.s3-us-west-2.amazonaws.com/content/ESSzxsWpAcCviLmQ_regular.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  font-family: Montserrat;
  text-transform: uppercase;
  text-align: center;
  margin-top: 2rem;
}

.SideCar .Navigation .Link {
  display: block;
  color: #fff;
  font-size: 1em;
  font-weight: 900;
  padding: 1rem 0rem;
}

.SideCar .Navigation .Link:hover,
.SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .NavigationMenuButton {
  color: #fff;
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* Show/hide navigation */
@media (min-width: 1070px) {
  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e {
  }

  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .linked-logo {
    flex-grow: 1;
  }

  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .logo {
    /* width: 12rem; */
  }

  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .navigation {
    flex-grow: 1;
  }

  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .Navigation {
    display: flex;
  }

  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .Navigation {
  }
  .DefaultHeaderTop5db8f3685f48476cBa1824d699c85a7e .logo {
    width: 22rem;
  }
}
.HeroHome211482b3a40cc406499a261f5a6843a65 {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 4rem;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-forest-backdrop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .hero-content {
  text-align: center;
  max-width: 75rem;
  width: 100%;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-sanctuary-title {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .hero-title-innerContainer {
  max-width: 25rem;
  width: 100%;
  text-align: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 h1,
.HeroHome211482b3a40cc406499a261f5a6843a65 .external-content h1 {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  letter-spacing: 0em;
  text-transform: uppercase;
  font-family: "Oseberg", sans-serif;
  font-weight: normal;
  margin: 0;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-main-content {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-yurt-image {
  width: 100%;
  height: auto;
  max-width: 50rem;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-button {
  width: 19.75rem;
  height: 4.25rem;
  padding: 1rem 2rem;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 3.125rem;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-reserve-button {
  background: #FCE2D9;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-host-button {
  background: #AF8F55;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .hero-banner-description-container {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .hero-banner-description-Innercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .description-para-container {
  margin-top: 1rem;
  max-width: 36rem;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .external-content p {
  margin-bottom: 0rem;
}

.HeroHome211482b3a40cc406499a261f5a6843a65 .description-para-container p {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .HeroHome211482b3a40cc406499a261f5a6843a65 .hero-content {
    margin-top: 6rem;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-forest-backdrop {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-sanctuary-title {
    margin-bottom: 1rem;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-main-content {
    margin-top: 1rem;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-yurt-image {
    max-width: 100%;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .hero-title-innerContainer {
    width: 100%;
    text-align: center;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .homepage-action-buttons {
    margin-top: 1rem;
    gap: 1.5rem;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 h1 {
    font-size: 1.8rem;
  }

  .HeroHome211482b3a40cc406499a261f5a6843a65 .description-para-container {
    padding: 0px 1.5rem;
  }
}
.HomePage140ace5f46864837Be02728caddb31ea {
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("https://mp1md-pub.s3-us-west-2.amazonaws.com/content/jblRhieXB7WQvOjD_regular.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.HomePage140ace5f46864837Be02728caddb31ea main {
  background: transparent;
  background-size: cover;
  background-position: center center;
  background-attachment: scroll;
}

.HomePage140ace5f46864837Be02728caddb31ea .content {
  height: auto;
  padding-bottom: 120px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .HomePage140ace5f46864837Be02728caddb31ea .hero-section {
    margin-top: 4.75rem;
    min-height: 70vh;
    width: 100%;
  }
}

.HomePage140ace5f46864837Be02728caddb31ea .services-title {
  background: rgba(27, 59, 32, 0.8);
}

.HomePage140ace5f46864837Be02728caddb31ea .services-title h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  padding-top: 2rem;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem;
  background: rgba(27, 59, 32, 0.8);
  gap: 1.25rem;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard {
  display: flex;
  flex-direction: column;
  min-height: 20rem;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .ImageColumn {
  display: flex;
  align-items: stretch;
  max-height: 100%;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .ImageColumn .ImageWrapper {
  width: 100%;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .ImageColumn img {
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem 3rem 3.75rem 5rem;
  background-color: transparent;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent .CardText {
  line-height: 200%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {
  .HomePage140ace5f46864837Be02728caddb31ea .services-title h2 {
    padding-left:2rem;
    padding-right:2rem;
  }
}

@media (max-width: 768px) {
  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent {
    padding: 3rem 0rem 3.75rem 0rem;
  }

  .MarketingCards .ButtonWrapper .Button {
    width: 100%;
    gap: 0.25rem;
    padding: 0.75rem 3rem;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .services-title h2 {
    margin: 0 2rem 0rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1600px) {
  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent .CardText {
    line-height: 250%;
    font-size: 120%;
  }
}

@media (min-width: 3200px) {
    padding: 3rem 6rem 3.75rem 2rem;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent .CardHeading {
  font-family: "Oseberg", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent .Button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.75rem 3rem;
  color: black;
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:first-of-type .CardTextContent .Button {
  background: var(--button-1);
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:first-of-type .CardTextContent .Button:hover {
  background: var(--button-1-hover);
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:nth-of-type(2) .CardTextContent .Button,
.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:nth-of-type(2) .CardTextContent .Button {
  background: var(--button-2);
}

.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:nth-of-type(2) .CardTextContent .Button:hover,
.HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:nth-of-type(3) .CardTextContent .Button:hover {
  background: var(--button-2-hover);
}

@media (min-width: 1024px) {
  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards {
    padding: 3.75rem 5rem;
    margin-bottom: 5rem;
    background: rgba(27, 59, 32, 0.8);
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard {
    flex-direction: row;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:first-of-type {
    margin-bottom: 5rem;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .MarketingCard:nth-of-type(2) {
    margin: 2.5rem 0;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .ImageColumn {
    width: 38%;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent {
    width: 62%;
  }

  .HomePage140ace5f46864837Be02728caddb31ea .MarketingCards .CardTextContent .CardHeading {
    text-align: left;
  }
}